import shopifyPursuitGenericDefaults from '../shopify-pursuit-generic/config.js';

const baseFitmentFields = globalThis.Convermax.config?.baseFitmentFields || ['Year', 'Make', 'Model'];
const extraFitmentFields = globalThis.Convermax.config?.extraFitmentFields || ['Submodel'];

globalThis.Convermax.isUserLoggedIn = window.document.querySelector(
  '.customer_nav_menu__wrapper .small_dropdown ul li a[href="/account/logout"]',
);

const baseUpdateCallback = shopifyPursuitGenericDefaults.Widgets.find(
  (w) => w.name === 'SearchResult',
)?.updateCallback;

function updateCallback() {
  baseUpdateCallback?.();

  const forms = window.document.querySelectorAll('.cm_SearchResult .product-item--info [action="/cart/add"]');
  if (!window.Convermax?.isUserLoggedIn && forms) {
    forms.forEach((form) => {
      window.theme.AddItemToCart(form);
    });
  }
}

export default {
  includes: ['shopify-pursuit-generic'],
  ...shopifyPursuitGenericDefaults,
  Widgets: [
    ...shopifyPursuitGenericDefaults.Widgets.filter(
      (w) => !['SearchVehicleWidget', 'SearchResult'].includes(w.name),
    ),
    {
      name: 'SearchVehicleWidget',
      type: 'VehicleWidget',
      fields: !!window.Convermax.config?.extraFieldsAsFilters && baseFitmentFields,
      visibleIf: () => !['/collections/new-marine-injectors'].includes(window.location.pathname),
    },
    {
      name: 'SearchResult',
      infiniteScrollDisabled: !!window.Convermax.config?.infiniteScrollDisabled,
      updateCallback,
    },
  ],
};
