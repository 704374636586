
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                function repeatI2(i, iIndex) {
                    return _createElement('span', {
                        'key': i,
                        'className': 'cm_star cm_star__' + (!(this.review_average - i) || this.review_average - i < 0.25 ? 'empty' : this.review_average - i < 0.75 ? 'half' : 'full')
                    });
                }
                return _createElement('div', { 'className': 'zoom-fade-animation-element-wrapper grid__item product-item-block view-mode-grid ' + (window.innerWidth < 1024 ? 'medium-up--one-third' : 'medium-up--one-quarter') + ' small--one-half' }, _createElement('div', { 'className': 'product-card product-card-wrapper' }, _createElement('div', { 'className': 'product-item__label-list' }, this.on_sale ? _createElement('span', {
                    'className': 'product-label product-label--on-sale',
                    'key': '312'
                }, '\n        ', Math.round((1 - this.price / this.compare_at_price) * 100), '% Off\n      ') : null, this.out_of_stock ? _createElement('span', {
                    'className': 'product-label product-label--soldout',
                    'key': '483'
                }, 'Sold Out') : null), _createElement('div', { 'className': 'product-item--media' }, _createElement('div', { 'className': 'product-card__image-with-placeholder-wrapper' }, _createElement('a', {
                    'href': this.url,
                    'className': 'list-view-item__link-image product-card__link-image'
                }, _createElement('div', { 'className': 'list-view-item__image-wrapper product-card__image-wrapper' }, _createElement('div', {}, _createElement('img', {
                    'className': 'list-view-item__image product-card__image zoom-fade-animation-element',
                    'src': this.imageOrDefault(this.resizeImage(this.image)),
                    'alt': this.removeHTML(this.title),
                    'onError': this.onImageError
                })))))), _createElement('div', { 'className': 'product-item--info' }, _createElement('div', { 'className': 'product-item--info_top' }, _createElement('a', mergeProps({
                    'href': this.vendor_url,
                    'className': 'product-item__vendor link'
                }, { dangerouslySetInnerHTML: { __html: this.vendor } })), _createElement('a', {
                    'href': this.url,
                    'className': 'item__link-title product-card__link-title',
                    'aria-label': this.removeHTML(this.title)
                }, _createElement('span', mergeProps({ 'className': 'h4 item__title product-card__title' }, { dangerouslySetInnerHTML: { __html: this.title } })))), _createElement('div', { 'className': 'product-item--info_bottom' }, [this.review_count ? _createElement.apply(this, [
                        'div',
                        {
                            'className': 'cm_review-stars',
                            'key': '17130'
                        },
                        _map([
                            0,
                            1,
                            2,
                            3,
                            4
                        ], repeatI2.bind(this)),
                        _createElement('span', { 'className': 'cm_review-count' }, '(', this.review_count, ')')
                    ]) : null], _createElement('div', { 'className': 'product-item__price_and_reviews_row' + (!!window.Convermax.isUserLoggedIn ? ' price-item__logged-in' : '') }, !!window.Convermax.isUserLoggedIn ? _createElement('span', {
                    'className': 'price__retail',
                    'key': '2265'
                }, 'Retail Price:') : null, _createElement('div', { 'className': 'price price--listing' + (this.on_sale ? ' price--on-sale' : '') }, !this.on_sale ? _createElement('div', {
                    'className': 'price__regular',
                    'key': '2472'
                }, _createElement('span', { 'className': 'price-item price-item--regular' }, this.formatPrice(this.price))) : null, this.on_sale ? _createElement('div', {
                    'className': 'price__sale',
                    'key': '2651'
                }, _createElement('span', { 'className': 'price-item price-item--sale' }, this.formatPrice(this.price)), _createElement('div', { 'className': 'price__compare' }, _createElement('s', { 'className': 'price-item price-item--regular' }, this.formatPrice(this.compare_at_price)))) : null)), !window.Convermax.isUserLoggedIn ? [
                    !this.out_of_stock && this.variant_ids.length === 1 ? _createElement('form', {
                        'method': 'post',
                        'action': '/cart/add',
                        'id': 'product_form_id_' + this.id,
                        'acceptCharset': 'UTF-8',
                        'className': 'product-item__action-list product-item__action-list--list-view-only button-stack with_quickview_btn',
                        'encType': 'multipart/form-data',
                        'key': '30191'
                    }, _createElement('input', {
                        'type': 'hidden',
                        'name': 'form_type',
                        'value': 'product'
                    }), _createElement('input', {
                        'type': 'hidden',
                        'name': 'utf8',
                        'value': '\u2713'
                    }), _createElement('input', {
                        'type': 'hidden',
                        'name': 'quantity',
                        'value': '1'
                    }), _createElement('input', {
                        'type': 'hidden',
                        'name': 'id',
                        'value': this.variant_ids[0]
                    }), _createElement('button', {
                        'type': 'submit',
                        'className': 'btn product-form__cart-submit btn--primary'
                    }, _createElement('span', { 'data-add-to-cart-text': true }, 'Add to cart'), _createElement('svg', {
                        'xmlns': 'http://www.w3.org/2000/svg',
                        'aria-hidden': 'true',
                        'focusable': 'false',
                        'data-prefix': 'fas',
                        'data-icon': 'shopping-basket',
                        'className': 'icon icon--basket svg-inline--fa fa-shopping-basket fa-w-18',
                        'role': 'img',
                        'viewBox': '0 0 576 512'
                    }, _createElement('path', {
                        'fill': 'currentColor',
                        'd': 'M576 216v16c0 13.255-10.745 24-24 24h-8l-26.113 182.788C514.509 462.435 494.257 480 470.37 480H105.63c-23.887 0-44.139-17.565-47.518-41.212L32 256h-8c-13.255 0-24-10.745-24-24v-16c0-13.255 10.745-24 24-24h67.341l106.78-146.821c10.395-14.292 30.407-17.453 44.701-7.058 14.293 10.395 17.453 30.408 7.058 44.701L170.477 192h235.046L326.12 82.821c-10.395-14.292-7.234-34.306 7.059-44.701 14.291-10.395 34.306-7.235 44.701 7.058L484.659 192H552c13.255 0 24 10.745 24 24zM312 392V280c0-13.255-10.745-24-24-24s-24 10.745-24 24v112c0 13.255 10.745 24 24 24s24-10.745 24-24zm112 0V280c0-13.255-10.745-24-24-24s-24 10.745-24 24v112c0 13.255 10.745 24 24 24s24-10.745 24-24zm-224 0V280c0-13.255-10.745-24-24-24s-24 10.745-24 24v112c0 13.255 10.745 24 24 24s24-10.745 24-24z'
                    }))), _createElement('input', {
                        'type': 'hidden',
                        'name': 'product-id',
                        'value': this.id
                    }), _createElement('input', {
                        'type': 'hidden',
                        'name': 'section-id',
                        'value': 'template--16389815697461__main'
                    })) : null,
                    !this.out_of_stock && this.variant_ids.length > 1 ? _createElement('a', {
                        'href': this.url,
                        'className': 'btn product-form__cart-submit btn--primary',
                        'key': '30193'
                    }, _createElement('span', {}, 'Choose options')) : null,
                    this.out_of_stock ? _createElement('button', {
                        'className': 'btn product-form__cart-submit btn--primary',
                        'disabled': true,
                        'key': '30195'
                    }, _createElement('span', {}, 'Out of Stock')) : null
                ] : null, !!window.Convermax.isUserLoggedIn ? [_createElement('spark-product-card', {
                        'parent-id': this.id,
                        'key': '54781'
                    })] : null, this.price ? _createElement('p', {
                    'className': 'affirm-as-low-as',
                    'data-amount': this.price * 100,
                    'data-affirm-type': 'logo',
                    'data-affirm-color': 'blue',
                    'key': '5617'
                }) : null))));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_related-products product-recommendations__inner half_row_mobile page-width zoom-fade-animation half_row_mobile zoomFade-animation' }, _createElement('div', { 'className': 'section-header text-center cm_related-products-title-container' }, _createElement('h2', { 'className': 'cm_related-products-title' }, 'You may also like')), _createElement.apply(this, [
        'div',
        { 'className': 'zoom-fade-animation grid grid--uniform grid--view-items use_align_height zoomFade-animation cm_related-products-list cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]));
}
        export const componentNames = []