//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-392:_5544,_2568,_6344,_9096,_226,_2824,_2976,_2152;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-392')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-392', "_5544,_2568,_6344,_9096,_226,_2824,_2976,_2152");
        }
      }catch (ex) {
        console.error(ex);
      }